import React from "react";

const Navbar = (props) => {
  return (
    <header>
      <nav className=" fixed-top navbar navbar-light bg-light shadow-sm p-3 mb-4 bg-body">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1">Growthscape</span>
          <div className="d-none d-lg-flex">{props.content}</div>
          <div className="d-flex d-lg-none"> {props.mobileContent} </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
