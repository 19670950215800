import React from "react";
import "./filter.css";

const Filter = (props) => {
  const { data, name } = props;

  return (
    <div style={{ width: "100%", display: "grid", placeContent: "center" }}>
      <div className="select-head multiselect" style={{ width: props.width || 150 }}>
        <div className="selectBox">
          <select id={`${name}_1`} className="select-field" name={name} onChange={props.onChange} value={props.value || ""}>
            {data?.length ? (
              data.map((e, i) => (
                <option className="option-select" key={i} value={e.value}>
                  {e.label}
                </option>
              ))
            ) : (
              <option disabled value="">
                No Data Found
              </option>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Filter;
