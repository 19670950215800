import React, { useEffect, useState, useRef } from "react";
import { VictoryScatter, VictoryZoomContainer, VictoryGroup, VictoryTooltip, VictoryLabel, VictoryLegend, VictoryChart, VictoryAxis, VictoryTheme, VictoryLine } from "victory";
import "./App.css";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import { useMediaQuery } from "react-responsive";
const Victory = (props) => {
  const [toggleEvalType, setToggleEvalType] = useState({ rtti: true, summative: true, semiAnnual: true });
  const [zoomDomain, setZoomDomain] = useState({ x: [new Date(2000, 1, 1), new Date()] });
  const [allowZoom, setAllowZoom] = useState(false);

  const [chartRef, setChartRef] = useState(null);
  const { data, modalOpen } = props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  // const isMidScreen = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 768 });
  const events = [
    {
      target: "data",
      eventHandlers: {
        onClick: (evt, clickedProps) => {
          const clickedIndex = clickedProps.index;
          props.setModalOpen({ open: true, data: clickedProps.datum });
          return [
            {
              target: "data",
              mutation: (props) => {
                return props.index === clickedIndex ? null : { style: { fill: "grey", width: 20 } };
              },
            },
          ];
        },
      },
    },
  ];

  useEffect(() => {
    if (!allowZoom) {
      setZoomDomain({ x: [new Date(2005, 1, 1), new Date()] });
    }
  }, [allowZoom]);

  const handleZoom = (domain) => {
    setZoomDomain(domain);
  };

  return (
    <div className="my-4 v-chart-container">
      <i
        className="fas fa-search mx-3"
        style={{ float: "right", fontSize: "1.3rem", color: allowZoom ? "blue" : "black", cursor: "pointer" }}
        title="Zoom"
        onClick={() => setAllowZoom(!allowZoom)}
      ></i>
      <ReactToPrint
        pageStyle="@page { size: auto; margin: 25mm; } }"
        trigger={() => <i className="fas fa-print mx-3" title="Print" style={{ float: "right", fontSize: "1.3rem", cursor: "pointer" }}></i>}
        content={() => chartRef}
      />

      <Pdf
        targetRef={chartRef}
        filename="chart.pdf"
        x={20}
        y={20}
        scale={isDesktopOrLaptop ? 0.7 : isTablet ? 1.3 : isMobile ? 2 : 1}
        options={{
          orientation: "p",
          unit: "mm",
          format: "a4",
          margin: "20mm",
        }}
      >
        {({ toPdf }) => <i className="fas fa-download mx-3" title="Download" style={{ float: "right", fontSize: "1.3rem", cursor: "pointer" }} onClick={toPdf}></i>}
      </Pdf>
      <div
        className="container"
        // ref={(ref) => {
        //   if (ref) setChartRef(ref);
        // }}
      >
        <div className="row w-100 ">
          <div className={`v-chart col-12 ${data?.length && "col-md-8"}`}>
            {data?.length && data?.[0]?.tickValues ? (
              <VictoryChart
                domainPadding={20}
                theme={VictoryTheme.material}
                containerComponent={
                  <VictoryZoomContainer
                    containerRef={(ref) => {
                      if (ref) setChartRef(ref);
                    }}
                    zoomDimension="x"
                    zoomDomain={zoomDomain}
                    onZoomDomainChange={handleZoom}
                    allowZoom={allowZoom}
                    allowPan={allowZoom}
                    style={!allowZoom ? { touchAction: "auto" } : {}}
                  />
                }
              >
                <VictoryAxis
                  scale={{ x: "time" }}
                  tickFormat={(x) => {
                    const dateObj = new Date(x);
                    const year = dateObj.getFullYear().toString();
                    const month = dateObj.toLocaleString("fr-fr", { month: "2-digit" });
                    return `${month}/${year}`;
                  }}
                  style={{ tickLabels: { fontSize: 8, angle: 0 } }}
                  fixLabelOverlap={true}
                />
                <VictoryAxis
                  dependentAxis
                  style={{ tickLabels: { fontSize: 8 } }}
                  domain={{ y: [0, 1] }}
                  tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]}
                  tickFormat={(x) => {
                    if (x % 3 === 0) return "B";
                    if (x % 3 === 1) return "D";
                    if (x % 3 === 2) return "S";
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  style={{ tickLabels: { fontSize: 8, padding: 25 } }}
                  domain={{ y: [1, 10] }}
                  tickValues={data?.[0]?.tickValues || ""}
                  tickLabelComponent={<VictoryLabel dy={0} />}
                />
                <VictoryLegend
                  title={`${data?.[0]?.resident_name + " - " + data?.[0]?.type}`}
                  centerTitle
                  x={100}
                  y={0}
                  orientation="horizontal"
                  gutter={20}
                  data={[
                    { name: "RTTI", symbol: { fill: "yellow" } },
                    { name: "Summative", symbol: { fill: "orange" } },
                    { name: "Semi Annual", symbol: { fill: "blue" } },
                  ]}
                  style={{
                    labels: { fontSize: 8 },
                    title: { fontSize: 11, textDecoration: "underline" },
                  }}
                  eventKey="x"
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onClick: () => {
                          return [
                            {
                              target: "data",
                              mutation: (props) => {
                                if (props.datum.name == "RTTI") setToggleEvalType({ ...toggleEvalType, rtti: !toggleEvalType.rtti });
                                else if (props.datum.name == "Summative") setToggleEvalType({ ...toggleEvalType, summative: !toggleEvalType.summative });
                                else if (props.datum.name == "Semi Annual") setToggleEvalType({ ...toggleEvalType, semiAnnual: !toggleEvalType.semiAnnual });

                                const opacity = props.style && props.style.opacity;
                                return opacity === 0.3 ? null : { style: { opacity: 0.3 } };
                              },
                            },
                          ];
                        },
                      },
                    },
                  ]}
                />

                <VictoryGroup>
                  {toggleEvalType.rtti && (
                    <VictoryScatter
                      name="scatter_wp"
                      style={{ data: { fill: "yellow" } }}
                      size={5}
                      data={data?.[0]?.rtti || []}
                      labelComponent={
                        <VictoryTooltip
                          labelComponent={<VictoryLabel lineHeight={1.5} dy={2} />}
                          text={({ datum }) => [`${datum.evalType}-${datum.label}`, "", `${new Date(datum.x).toLocaleDateString("en-US")}`, `Submitted by:`, `Rajiv John`, `Internal Medicine`]}
                          style={{ fontSize: "8px", padding: 10 }}
                          flyoutStyle={{
                            stroke: "black",
                            strokeWidth: 0.2,
                            opacity: 0.9,
                          }}
                          renderInPortal
                        />
                      }
                      events={events}
                    />
                  )}

                  {toggleEvalType.summative && (
                    <VictoryScatter
                      name="scatter_wp"
                      events={events}
                      data={data?.[0]?.summative || []}
                      style={{ data: { fill: "orange" } }}
                      size={5}
                      labelComponent={
                        <VictoryTooltip
                          labelComponent={<VictoryLabel lineHeight={1.5} dy={2} />}
                          text={({ datum }) => [`${datum.evalType}-${datum.label}`, "", `${new Date(datum.x).toLocaleDateString("en-US")}`, `Submitted by:`, `Rajiv John`, `Internal Medicine`]}
                          style={{ fontSize: "8px", padding: 10 }}
                          flyoutStyle={{
                            stroke: "black",
                            strokeWidth: 0.2,
                            opacity: 0.9,
                          }}
                          renderInPortal
                        />
                      }
                    />
                  )}
                  {toggleEvalType.semiAnnual && (
                    <VictoryScatter
                      name="scatter_wp"
                      events={events}
                      data={data?.[0]?.semiAnnual || []}
                      style={{ data: { fill: "blue" } }}
                      size={5}
                      labelComponent={
                        <VictoryTooltip
                          labelComponent={<VictoryLabel lineHeight={1.5} dy={2} />}
                          text={({ datum }) => [`${datum.evalType}-${datum.label}`, "", `${new Date(datum.x).toLocaleDateString("en-US")}`, `Submitted by:`, `Rajiv John`, `Internal Medicine`]}
                          style={{ fontSize: "8px", padding: 10 }}
                          flyoutStyle={{
                            stroke: "black",
                            strokeWidth: 0.2,
                            opacity: 0.9,
                          }}
                          renderInPortal
                        />
                      }
                    />
                  )}
                  <VictoryLine
                    style={{
                      data: { stroke: "#c43a31" },
                      parent: { border: "1px solid #ccc" },
                    }}
                    data={props.growthCurve}
                  />
                  <VictoryScatter
                    style={{
                      data: { fill: "#c43a31" },
                      // parent: { border: "1px solid #ccc" },
                    }}
                    size={3}
                    data={props.growthCurve}
                  />
                </VictoryGroup>
              </VictoryChart>
            ) : (
              <div style={{ height: "100%", display: "grid", placeContent: "center" }}> No data to show you right now !</div>
            )}
          </div>
          {modalOpen.open ? (
            <div className="col-12 col-md-4 d-flex align-items-center">
              <div className="narrative-div  my-2">
                <div>
                  <h6>{`${modalOpen.data.evalType}- ${modalOpen.data.label}`}</h6>
                </div>
                <div>
                  <h5>Narrative Description: </h5>
                  <p>{modalOpen.data?.description}</p>
                </div>
                <div>
                  <h5> Comment: </h5>
                  <p>{modalOpen.data?.comment}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Victory;
