import React, { useState } from "react";
import Data from "./data";

import Filter from "./filter";
import Modal from "./modal";
import Victory from "./victory";
import "./parent.css";
import Navbar from "./Navbar";
import DatePicker from "react-datepicker";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

const Parent = () => {
  const filterData = [
    { label: "Patient Care", value: "patientcare" },
    { label: "Citizenship", value: "citizenship" },
  ];

  const filterData2 = [
    { label: "Dr. William", value: "1" },
    { label: "Dr. Mira Patel", value: "2" },
  ];

  const filterData3 = [
    { label: "Faculty 1", value: "1" },
    { label: "Faculty 2", value: "2" },
  ];
  const [value, setValue] = useState("patientcare");
  const [residentValue, setResidentValue] = useState({ resident: filterData2?.[0], faculty: filterData3?.[0] });
  console.log("residentValue: ", residentValue);

  const [mobileFilterToggle, setMobileFilterToggle] = useState(false);
  const [growthModalOpen, setGrowthModalOpen] = useState(false);
  const [growthCurve, setGrowthCurve] = useState([{ id: 0, x: new Date(), y: null }]);

  const [growthCurveValue, setGrowthCurveValue] = useState({ id: 0, x: "", y: "" });

  const [modalOpen, setModalOpen] = useState({ open: false, data: {} });

  const onChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const data = Data.filter((i) => i.name === value && i.resident == residentValue.resident.value && i.faculty == residentValue.faculty.value);

  const handleResidentChange = (value, name) => {
    setResidentValue({ ...residentValue, [name]: value });
  };

  const addInput = () => {
    let curve = [...growthCurve, { x: "", y: "", id: growthCurve?.length }];
    setGrowthCurve(curve);
  };
  const deleteInput = (id) => {
    let filtered = growthCurve?.filter((i) => i.id !== id);
    setGrowthCurve(filtered);
  };

  const add = (item) => {
    let s = growthCurveValue;
    s = { ...s, item };
    setGrowthCurveValue(s);
    let newArray = [...growthCurve];
    let arr = newArray.map((i) => {
      if (i.id == item.id) i = item;
      return i;
    });
    setGrowthCurve(arr);
  };

  return (
    <div>
      <Navbar
        content={
          <div className="d-flex justify-content-between">
            <div className="mx-1">
              <button className="btn btn-primary" onClick={() => setGrowthModalOpen(true)}>
                Growth Curve
              </button>
            </div>
            <div className="mx-1">
              <Filter {...{ value, onChange }} name="evalType" data={filterData} />
            </div>
            {/* <Filter {...{ onChange: handleResidentChange }} name="resident" data={filterData2} value={residentValue} /> */}
            <div className="mx-1" style={{ width: 200 }}>
              <Select value={residentValue?.resident || ""} name="resident" onChange={(value) => handleResidentChange(value, "resident")} options={filterData2} />
            </div>
            <div className="mx-1" style={{ width: 200 }}>
              <Select value={residentValue?.faculty || ""} name="faculty" onChange={(value) => handleResidentChange(value, "faculty")} options={filterData3} />
            </div>
          </div>
        }
        mobileContent={
          <div role="button" className="pt-1">
            <i className="fas fa-filter" style={{ fontSize: 18, color: "#000", cursor: "pointer" }} onClick={() => setMobileFilterToggle(!mobileFilterToggle)}></i>
            {mobileFilterToggle ? (
              <ul className="filter-dropdown" style={{ top: 60 }}>
                <li role="presentation" className="filter-menu-head">
                  <div className="my-2">
                    <Filter {...{ value, onChange }} width={200} name="evalType" data={filterData} />
                  </div>
                  {/* <div className="my-2">
                    <Filter {...{ onChange: handleResidentChange }} name="resident" data={filterData2} value={residentValue} />
                  </div> */}
                  <div className="my-2" style={{ width: 200 }}>
                    <Select value={residentValue.resident} name="resident" onChange={(value) => handleResidentChange(value, "resident")} options={filterData2} />
                  </div>
                  <div className="my-2" style={{ width: 200 }}>
                    <Select value={residentValue.faculty} name="faculty" onChange={(value) => handleResidentChange(value, "faculty")} options={filterData3} />
                  </div>
                </li>
                <div className="d-flex mt-4 mb-2" style={{ alignItems: "center", justifyContent: "center" }}></div>
              </ul>
            ) : null}
          </div>
        }
      />
      <main>
        {/* {modalOpen?.open && modalOpen?.data ? (
          <div style={{ width: 30, height: 30 }}>
            <Modal
              heading={`${modalOpen.data.evalType}- ${modalOpen.data.label}`}
              body={
                <div>
                  <div>
                    <h5>Narrative Description: </h5>
                    <p>{modalOpen.data?.description}</p>
                  </div>
                  <div>
                    <h5> Comment: </h5>
                    <p>{modalOpen.data?.comment}</p>
                  </div>
                </div>
              }
              onCancel={() => setModalOpen({ open: false, data: {} })}
            />
          </div>
        ) : null} */}

        {growthModalOpen ? (
          <div style={{ width: 60, height: 30 }}>
            <Modal
              heading={"Add Growth Curve"}
              style={{ minHeight: 300 }}
              body={
                <>
                  {growthCurve?.map((i, index) => {
                    return (
                      <div className="container mb-2">
                        <div className="row">
                          <div className="col-3">
                            <h6>Y axis </h6>
                            <input
                              min={0}
                              className="form-control"
                              style={{ width: 70 }}
                              type="number"
                              id={index}
                              name="y"
                              value={i.y}
                              onChange={(e) => add({ x: i.x, y: Number(e.target.value), id: index })}
                            />
                          </div>
                          <div className="col-7">
                            <h6>X axis </h6>
                            <DatePicker className="form-control" selected={i.x} onChange={(date) => add({ x: date, y: i.y, id: index })} showYearPicker />
                          </div>
                          <div className="col-2 d-flex align-items-end mb-2">
                            <i className="fas fa-plus" style={{ cursor: "pointer" }} onClick={() => addInput()}></i>
                            <i class="far fa-trash-alt mx-2" style={{ cursor: "pointer" }} onClick={() => deleteInput(i.id)}></i>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              }
              onCancel={() => setGrowthModalOpen(false)}
            />
          </div>
        ) : null}

        <Victory data={data} {...{ setModalOpen, growthCurve, modalOpen }} />
      </main>
    </div>
  );
};

export default Parent;
