const data = [
  {
    resident_name: "Dr. William",
    resident: "1",
    faculty_name: "Faculty 1",
    faculty: "1",
    name: "patientcare",
    type: "Patient Care",
    tickValues: ["R1", null, null, "R2", null, null, "I1", null, null, "M1", null, null, "M2", null, null, "E1"],
    rtti: [
      {
        x: new Date(2011, 1, 1),
        y: 8,
        label: "I1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient 11…",
      },
      {
        x: new Date(2010, 1, 1),
        y: 17,
        label: "E1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 11,
        label: "M1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 8,
        label: "I1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 8,
        label: "I1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    summative: [
      {
        x: new Date(2011, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 15,
        label: "E1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 6,
        label: "I1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 15,
        label: "E1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 1, 1),
        y: 6,
        label: "I1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 1, 1),
        y: 10,
        label: "M1 D",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 1, 1),
        y: 11,
        label: "M1 S",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    semiAnnual: [
      {
        x: new Date(2011, 20, 1),
        y: 9,
        label: "M1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 20, 1),
        y: 15,
        label: "E1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 20, 1),
        y: 6,
        label: "I1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 20, 1),
        y: 9,
        label: "M1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 20, 1),
        y: 15,
        label: "E1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 20, 1),
        y: 6,
        label: "I1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 20, 1),
        y: 9,
        label: "M1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 20, 1),
        y: 10,
        label: "M1 D",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 20, 1),
        y: 11,
        label: "M1 S",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
  },
  {
    resident_name: "Dr. Mira Patel",
    resident: "2",
    faculty_name: "Faculty 2",
    faculty: "2",
    name: "patientcare",
    type: "Patient Care",
    tickValues: ["R1", null, null, "R2", null, null, "I1", null, null, "M1", null, null, "M2", null, null, "E1"],
    rtti: [
      {
        x: new Date(2011, 1, 1),
        y: 8,
        label: "I1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2005, 1, 1),
        y: 17,
        label: "E1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 11,
        label: "M1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 8,
        label: "I1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 8,
        label: "I1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    summative: [
      {
        x: new Date(2011, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 15,
        label: "E1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 6,
        label: "I1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 15,
        label: "E1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 1, 1),
        y: 6,
        label: "I1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 1, 1),
        y: 9,
        label: "M1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 1, 1),
        y: 10,
        label: "M1 D",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 1, 1),
        y: 11,
        label: "M1 S",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    semiAnnual: [
      {
        x: new Date(2011, 20, 1),
        y: 9,
        label: "M1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 20, 1),
        y: 15,
        label: "E1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 20, 1),
        y: 6,
        label: "I1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 20, 1),
        y: 9,
        label: "M1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 20, 1),
        y: 15,
        label: "E1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 20, 1),
        y: 6,
        label: "I1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 20, 1),
        y: 9,
        label: "M1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 20, 1),
        y: 10,
        label: "M1 D",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 20, 1),
        y: 11,
        label: "M1 S",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
  },
  {
    resident_name: "Dr. Mira Patel",
    resident: "2",
    faculty_name: "Faculty 2",
    faculty: "2",
    name: "citizenship",
    type: "Citizenship",
    tickValues: ["P1", null, null, "C1", null, null, "C2", null, null, "RM", null, null, "L"],
    rtti: [
      {
        x: new Date(2010, 1, 1),
        y: 2,
        label: "P1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2011, 1, 1),
        y: 13,
        label: "L D",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 5,
        label: "C1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 2,
        label: "P1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 2,
        label: "P1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    summative: [
      {
        x: new Date(2010, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 11,
        label: "RM S",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 6,
        label: "C2 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 12,
        label: "L B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 1, 1),
        y: 6,
        label: "C2 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 1, 1),
        y: 4,
        label: "C1 D",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 1, 1),
        y: 8,
        label: "C2 S",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    semiAnnual: [
      {
        x: new Date(2010, 20, 1),
        y: 3,
        label: "C1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 20, 1),
        y: 11,
        label: "RM S",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 20, 1),
        y: 6,
        label: "C2 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 20, 1),
        y: 3,
        label: "C1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 20, 1),
        y: 12,
        label: "L B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 20, 1),
        y: 6,
        label: "C2 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 20, 1),
        y: 3,
        label: "C1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 20, 1),
        y: 4,
        label: "C1 D",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 20, 1),
        y: 8,
        label: "C2 S",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
  },
  {
    resident_name: "Dr. William",
    resident: "1",
    faculty_name: "Faculty 1",
    faculty: "1",
    name: "citizenship",
    type: "Citizenship",
    tickValues: ["P1", null, null, "C1", null, null, "C2", null, null, "RM", null, null, "L"],
    rtti: [
      {
        x: new Date(2008, 1, 1),
        y: 2,
        label: "P1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2011, 1, 1),
        y: 13,
        label: "L D",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 5,
        label: "C1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 2,
        label: "P1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 2,
        label: "P1 S",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "RTTI",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    summative: [
      {
        x: new Date(2010, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 1, 1),
        y: 11,
        label: "RM S",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 1, 1),
        y: 6,
        label: "C2 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 1, 1),
        y: 12,
        label: "L B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 1, 1),
        y: 6,
        label: "C2 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 1, 1),
        y: 3,
        label: "C1 B",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 1, 1),
        y: 4,
        label: "C1 D",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 1, 1),
        y: 8,
        label: "C2 S",
        evalType: "Summative",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
    semiAnnual: [
      {
        x: new Date(2010, 20, 1),
        y: 3,
        label: "C1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2012, 20, 1),
        y: 11,
        label: "RM S",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2013, 20, 1),
        y: 6,
        label: "C2 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2014, 20, 1),
        y: 3,
        label: "C1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2015, 20, 1),
        y: 12,
        label: "L B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2016, 20, 1),
        y: 6,
        label: "C2 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2017, 20, 1),
        y: 3,
        label: "C1 B",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2018, 20, 1),
        y: 4,
        label: "C1 D",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
      {
        x: new Date(2019, 20, 1),
        y: 8,
        label: "C2 S",
        evalType: "Semi Annual",
        description:
          "This step requires a mastery of broaderbased current medical knowledge. This resident is accurate in answering the right questions about a patient's care and predicting what to do next. They consistently",
        comment: "Mira did an exceptional job with a patientdischarge today. She engaged her patient…",
      },
    ],
  },
];

export default data;
