import React from "react";
import ReactDOM from "react-dom";
import "./modal.css";

const modalWrapper = {
  position: "fixed",
  width: "100%",
  height: "100%",
  background: "rgba(0,0,0,0.5)",
  left: 0,
  top: 0,
  overflow: "auto",
  zIndex: 9999,
  opacity: 2,
};

const Modal = (props) => {
  const escFunction = React.useCallback((event) => {
    if (event?.keyCode === 27) props.onCancel();
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return ReactDOM.createPortal(
    <div style={modalWrapper}>
      <div className={`modal-dialog`}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{props.heading}</h4>
            <button id={`close_${props.id}`} type="button" className="close" onClick={() => props.onCancel()}>
              &times;
            </button>
          </div>
          <div className="modal-body" style={props.style}>
            {props.body}
          </div>

          {/* <div className="modal-footer">
            <div className="col-12 actions text-center">
              <button id="modal_close" className="close-btn" onClick={() => props.onCancel()}>
                Close
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>,
    document.querySelector("#root")
  );
};

export default Modal;
